<template>
  <div class="gashapon-container">
    <!-- 扭蛋机背景 -->
    <div class="gashapon-bg">
      <!-- 标题 -->
      <p class="gashapon-title">{{ gashaponInfo.lotteryName}}</p>
      <!-- <img
        class="gashapon-title"
        src="../../assets/icon/gashapon/thanks-title.png"
      /> -->
      <!-- 轮播 -->
      <van-notice-bar
        v-if="bannerListText"
        ref="noticeBar"
        class="gashpon-banner"
        :speed="50"
        height="100%"
        :scrollable="true"
        :text="bannerListText"
      >
      </van-notice-bar>
      <!-- 小球滚动 -->
      <img
        :style="calcStyle(i)"
        v-for="(i,j) in capsuleList"
        class="gashapon-transform"
        :class="isActive ? 'move' + j : 'gashaponAni' + j"
        :key="j"
        :src="require('../../assets/icon/gashapon/gashapon.png')"
      />
      <!-- 剩余抽奖次数 -->
      <div
        v-if="availableNum || availableNum == 0"
        class="gashpon-lottery-size row-center"
      >
        <p class="gashpon-lottery-size-tip">{{'剩余次数' + availableNum }}</p>
      </div>
      <!-- 抽奖 -->
      <div class="lottery-button-content row-between">
        <img
          @click="capsuleMove(1)"
          class="lottery-button-one"
          src="../../assets/icon/gashapon/strokes-1.png"
        />
        <img
          @click="capsuleMove(5)"
          class="lottery-button-item"
          src="../../assets/icon/gashapon/strokes-5.png"
        />
        <img
          @click="capsuleMove(10)"
          class="lottery-button-item"
          src="../../assets/icon/gashapon/strokes-10.png"
        />
      </div>
      <!-- 抽奖记录 -->
      <div
        @click="getLotteryRecord()"
        class="lottery-record-content row-center"
      >
        我的抽奖记录
      </div>
    </div>
    <div class="lottery-size-rule-content">
      <!-- 获取抽奖次数 -->
      <div
        v-if="gashaponInfo.inviteAddDrawSwitch == 1 || gashaponInfo.assetsExchangeSwitch == 1 || gashaponInfo.integralExchangeSwitch == 1"
        class="get-lottery-size"
      >
        <img
          class="get-lottery-size-title"
          src="../../assets/icon/gashapon/get-lottery-size.png"
        />
        <!-- 邀请好友获得次数 -->
        <div
          v-if="gashaponInfo.inviteAddDrawSwitch == 1"
          class="get-lottery-size-item row-between"
        >
          <div style=" width: 60%;">
            <p class="get-lottery-size-item-title">邀请好友获得抽奖次数</p>
            <p class="get-lottery-size-item-tip">邀请{{gashaponInfo.inviteUserNum}}位好友完成注册{{gashaponInfo.inviteUserStatus == 2 ? '并实名' : ''}}，可获得{{gashaponInfo.inviteUserDrawNum}}次抽奖机会</p>
          </div>
          <div
            @click="goInvite"
            class="get-lottery-size-button row-center"
          >立即邀请</div>
        </div>
        <!-- 藏品兑换获得次数 -->
        <div
          v-if="gashaponInfo.assetsExchangeSwitch == 1"
          class="get-lottery-size-item row-between"
        >
          <div>
            <p class="get-lottery-size-item-title">藏品兑换抽奖次数</p>
            <p class="get-lottery-size-item-tip">拥有指定藏品可兑换抽奖机会</p>
          </div>
          <div
            @click="gashaponExchange(1)"
            class="get-lottery-size-button row-center"
          >立即兑换</div>
        </div>
        <!-- 积分兑换获得次数 -->
        <div
          v-if="gashaponInfo.integralExchangeSwitch == 1"
          class="get-lottery-size-item row-between"
        >
          <div>
            <p class="get-lottery-size-item-title">积分兑换抽奖次数</p>
            <p class="get-lottery-size-item-tip">消耗积分可兑换抽奖机会</p>
          </div>
          <div
            @click="gashaponExchange(2)"
            class="get-lottery-size-button row-center"
          >立即兑换</div>
        </div>
      </div>
      <!-- 抽奖规则 -->
      <div
        style="margin-top: 40px;"
        v-if="gashaponInfo.rules"
        class="get-lottery-size"
      >
        <img
          class="get-lottery-size-title"
          src="../../assets/icon/gashapon/lottery-rule.png"
        />
        <div
          class="lottery-rule"
          v-html="gashaponInfo.rules"
        >
        </div>

      </div>
      <div class="gashapon-agreement col-center">
        <img
          class="gashapon-logo"
          src="../../assets/icon/aboutUs/logo.png"
        />
        <p class="gashapon-logo-title">Meta彼岸</p>
        <p class="gashapon-agreement-title">智链2.0提供区块链技术支持</p>
        <p class="gashapon-agreement-tip">Copyright © GenimousChain Ltd.All rights reserved</p>
      </div>
    </div>
    <!-- 1次抽奖效果 -->
    <div
      v-if="lottertShade"
      class="lottery-shade-bg"
    >
    </div>
    <!-- 抽奖动画 -->
    <div
      v-if="lottertShade"
      class="lottery-content"
    >
      <img
        v-if="step == 1 || step == 2 || (step == 3  && lotteryList.length == 1)"
        class="lottery-bg1"
        src="../../assets/icon/gashapon/lottery-bg1.png"
      />
      <img
        v-if="step == 1"
        class="lottery-ball lotteryAni"
        src="../../assets/icon/gashapon/gashapon.png"
      />
      <img
        v-if="step == 2"
        class="lottery-ball-up lotteryMoveUp"
        src="../../assets/icon/gashapon/ball-up.png"
      />
      <img
        v-if="step == 2"
        class="lottery-ball-down"
        src="../../assets/icon/gashapon/ball-down.png"
      />
      <img
        v-if="step == 3  && lotteryList.length == 1 && lotteryList[0].winOrNot == 1"
        class="lottery-open-ball"
        src="../../assets/icon/gashapon/open-ball1.png"
      />
      <img
        v-if="step == 3  && lotteryList.length == 1 && lotteryList[0].winOrNot == 0"
        class="lottery-open-ball"
        src="../../assets/icon/gashapon/open-ball2.png"
      />
      <!-- 一次抽奖结果 -->
      <p
        v-if="step == 3  && lotteryList.length == 1"
        class="lottery-open-title"
      >{{ lotteryList[0].winOrNot == 1 ? '恭喜您中奖啦！' : '很遗憾您未中奖' }}</p>
      <img
        v-if="step == 3 && lotteryList.length == 1"
        class="lottery-open-content"
        :src="lotteryList[0].lotteryItemImg"
      />
      <p
        v-if="step == 3  && lotteryList.length == 1"
        class="lottery-open-content-title"
      >{{ lotteryList[0].lotteryItemName }}</p>
      <img
        v-if="step == 3  && lotteryList.length == 1"
        @click="closeLottery()"
        class="lottery-open-close-button"
        src="../../assets/icon/gashapon/close.png"
      />
      <img
        v-if="step == 3  && lotteryList.length == 1"
        @click="capsuleMove(1)"
        class="lottery-open-repreat-button"
        src="../../assets/icon/gashapon/repreat-button.png"
      />
      <!-- 5次10次抽奖结果 -->
      <div
        class="lottery-open-content-more"
        v-if="step == 3  && lotteryList.length > 1"
      >
        <!-- 抽奖结果区域 -->
        <div class="lottery-open-list-content">
          <div
            class="lottery-open-list-content-item"
            v-for="(item,index) in lotteryList"
            :key="index"
          >
            <div
              class="col-center"
              :class="openBallIndex < index ? 'lottery-open-list-content-item1' : 'lottery-open-list-content-item2' "
            >
              <img
                v-if="openBallIndex>= index"
                class="lottery-open-list-item-lottery-img"
                :src="item.lotteryItemImg"
              />
            </div>
            <!--藏品 -->

            <div
              v-if="openBallIndex >= index"
              class="lottery-open-list-item-bottom row-center"
            >
              <p>{{item.lotteryItemName}}</p>
            </div>
          </div>
        </div>
        <img
          v-if="step == 3  && lotteryList.length > 1"
          @click="closeLottery()"
          class="lottery-open-more-close-button"
          src="../../assets/icon/gashapon/close.png"
        />
        <img
          v-if="step == 3  && lotteryList.length == 5"
          @click="capsuleMove(5)"
          class="lottery-open-more-repreat-button"
          src="../../assets/icon/gashapon/repreat-5.png"
        />
        <img
          v-if="step == 3  && lotteryList.length == 10"
          @click="capsuleMove(10)"
          class="lottery-open-more-repreat-button"
          src="../../assets/icon/gashapon/repreat-10.png"
        />
      </div>
    </div>
    <el-dialog
      title="账户未登录"
      :visible.sync="loginVisible"
      @confirm="goLogin"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="loginVisible = false">取 消</el-button>
        <div
          @click="goLogin()"
          class="confirm-button row-center"
        >去登录</div>
      </span>
    </el-dialog>
    <el-dialog
      title="账户未实名认证"
      :visible.sync="authVisible"
      @confirm="$router.push('/certification')"
      class="donation-dialog"
      center
    >
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <el-button @click="authVisible = false">取 消</el-button>
        <div
          @click="$router.push('/certification')"
          class="confirm-button row-center"
        >去认证</div>
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="openVisible"
      :before-close="handleClose"
      @confirm="$router.go(-1)"
      class="donation-dialog"
      center
    >
      <p
        style="width: 90% !important;margin-left: 5%;"
        class="donation-error-title"
      >{{errorTip}}</p>
      <span
        slot="footer"
        style="margin-top:2rem;"
        class="dialog-footer row-center"
      >
        <div
          @click="$router.go(-1)"
          class="donation-button row-center"
        >确定</div>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// import api from '../api/index-client'
import Vue from 'vue'
import Cookies from 'js-cookie'
import api from '../../api/index-client'
import { Carousel,CarouselItem,Button,Dialog } from 'element-ui';
import { List,Popup,NoticeBar,Swipe,SwipeItem } from 'vant';
Vue.use(Dialog);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(NoticeBar);
Vue.use(List);
Vue.use(Popup);
Vue.use(Button)
Vue.use(Carousel);
Vue.use(CarouselItem)

// 生成随机数
export default {
  data () {
    return {
      errorTip: '',
      openVisible: false,
      authVisible: false,
      loginVisible: false,
      bannerListText: '',
      openBallIndex: 0,
      lotteryId: null,
      gashaponInfo: {
        assetsExchangeSwitch: 0,
        integralExchangeSwitch: 0,
        inviteAddDrawSwitch: 0,
        inviteUserNum: '',
        inviteUserStatus: '',
        inviteUserDrawNum: ''
      },
      step: 0,
      lottertShade: false,
      isActive: false,
      availableNum: null,
      capsuleList: [],
      timer: null,
      isAuth: false,
      lotteryList: [
        {
          lotteryItemImg: '',
          lotteryItemName: '',
        }
      ]
    }
  },
  watch: {
    step () {
      if (this.step == 1) {
        clearTimeout(this.timer)
        setTimeout(() => {
          this.step = 2
        },500)
      } else if (this.step == 2) {
        setTimeout(() => {
          this.step = 3
          setTimeout(() => {
            this.openBallIndex = 1
          },500)
        },500)
      }
    },
    openBallIndex () {
      if (this.openBallIndex < 11 && this.step == 3) {
        this.timer = setTimeout(() => {
          this.openBallIndex++
        },500)
      } else {
        clearTimeout(this.timer)
      }
    }
  },
  computed: {
    //动态绑定style
    calcStyle () {
      return function (i) {
        return {
          position: 'absolute',
          width: '16%',
          top: i.top,
          left: i.left
        }
      }
    }
  },
  mounted () {
    // 生成初始小球
    this.getCapsule()
    // 获取活动数据
    this.getExchangePlans()
    if (Cookies.get('Login')) {
      this.getMyInfo()
    }
  },
  methods: {
    // 获取个人基本信息
    getMyInfo () {
      api
        .get('userinfo')
        .then(result => {
          if (result.data.success) {
            this.isAuth = result.data.data.hasRealname
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 邀请好友
    goInvite () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        this.$router.push('/invitationFriends')
      }

    },
    // 获取用户兑换方案
    getExchangePlans () {
      api
        .get('lottery/detail/' + this.$route.query.code)
        .then(result => {
          if (result.data.success) {
            this.gashaponInfo = result.data.data
            this.gashaponInfo.lotteryName = this.gashaponInfo.lotteryName.length > 16 ? this.gashaponInfo.lotteryName.substring(0,16) : this.gashaponInfo.lotteryName
            this.getCapsule()
            // 获取跑马灯数据
            this.getGashaponBanner()
            if (Cookies.get('Login')) {
              // 获取活动数据
              this.getUserDrawNum()
            }
            // 获取用户抽奖次数


          } else {
            this.openVisible = true
            this.errorTip = result.data.msg
            this.$store.commit('HIDE_APPLOADING')
            // this.$toast({
            //   message: result.data.msg,
            //   icon: require('../../assets/icon/toast-error.png'),
            // });
          }
        })
    },
    handleClose () {
      // this.$router.go(-1)

    },
    // 登录
    goLogin () {
      localStorage.setItem('loginToGashapon','gashapon?code=' + this.$route.query.code)
      this.$router.push('/login')
    },
    // 查询用户抽奖次数
    getUserDrawNum () {
      api
        .get('lottery/getUserDrawNum?lotteryCode=' + this.$route.query.code)
        .then(result => {
          if (result.data.success) {
            this.availableNum = result.data.data.availableNum
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    // 生成随机数
    getRandomArbitrary (min,max) {
      min = Math.ceil(min)
      max = Math.floor(max)
      return Math.floor(Math.random() * (max - min + 1)) + min //含最大值，含最小值 
    },
    // 关闭
    closeLottery () {
      this.lottertShade = false
      this.step = 0
    },
    getLotteryRecord () {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        this.$router.push('/gashaponRecord?lotteryId=' + this.gashaponInfo.id)
      }

    },
    // 立即兑换
    gashaponExchange (e) {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else {
        console.log(e);
        if (e == 1) {
          this.$router.push('/collectionExchange?lotteryCode=' + this.$route.query.code)
        } else {
          this.$router.push('/integralExchange?lotteryCode=' + this.$route.query.code)
        }
      }
    },
    // 小球随机滚动
    capsuleMove (drawNum) {
      if (!Cookies.get('Login')) {
        this.loginVisible = true
      } else if (!this.isAuth) {
        this.authVisible = true
      } else if (this.availableNum < drawNum) {
        this.$toast({
          message: '抽奖次数不足',
          icon: require('../../assets/icon/toast-error.png'),
        });
      } else {
        this.lottertShade = false
        this.step = 0
        this.isActive = true
        this.openBallIndex = 0
        api
          .get('lottery/luckyDraw?lotteryCode=' + this.$route.query.code + '&drawNum=' + drawNum)
          .then(result => {
            if (result.data.success) {
              console.log(result);
              this.lotteryList = result.data.data
              this.getUserDrawNum()
              setTimeout(() => {
                this.isActive = false
                this.lottertShade = true
                this.step = 1
              },2000)
            } else {
              setTimeout(() => {
                this.isActive = false
              },2000)
              this.$toast({
                message: result.data.msg,
                icon: require('../../assets/icon/toast-error.png'),
              });
            }
          })
      }
    },
    // 获取走马灯数据
    getGashaponBanner () {
      api
        .get('lottery/getMarquee?lotteryId=' + this.gashaponInfo.id)
        .then(result => {
          if (result.data.success) {
            if (result.data.data) {
              this.bannerListText = ''
              result.data.data.map(i => {
                var text = i.phone + '获得' + i.itemName + ' ' + i.createTime
                if (i == result.data.data.length - 1) {
                  this.bannerListText += text
                } else {
                  this.bannerListText += text + '\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0\u00a0'
                }
              })
            }
            this.$store.commit('HIDE_APPLOADING')
          } else {
            this.$toast({
              message: result.data.msg,
              icon: require('../../assets/icon/toast-error.png'),
            });
          }
        })
    },
    getCapsule () {
      //小球数量
      this.capsuleList = [
        {
          top: '39%',
          left: '20%',
        },
        {
          top: '39%',
          left: '47%',
        },
        {
          top: '48%',
          left: '70%',
        },
        {
          top: '49%',
          left: '53%',
        },
        {
          top: '38%',
          left: '34%',
        },
        {
          top: '38%',
          left: '68%',
        },
        {
          top: '40%',
          left: '57%',
        },
        {
          top: '44%',
          left: '27%',
        },
        {
          top: '47%',
          left: '18%',
        },
        {
          top: '50%',
          left: '25%',
        },
        {
          top: '50%',
          left: '63%',
        },
        {
          top: '43%',
          left: '47%',
        },
        {
          top: '50%',
          left: '40%',
        },
        {
          top: '44%',
          left: '37%',
        },
        {
          top: '43%',
          left: '63%',
        }

      ]
    }
  },
}
</script>
<style lang="less" scoped>
.gashapon-container {
  height: 100vh;
  overflow-y: auto;
  width: 100%;
  z-index: 19;
  .gashapon-bg {
    width: 100vw;
    height: 185.33vw;
    position: relative;
    background-image: url('../../assets/icon/gashapon/gashapon-bg.png');
    background-repeat: repeat;
    background-attachment: scroll;
    background-position: 0% 0%;
    background-size: 100% auto;
    background-color: transparent;
  }
  .gashapon-title {
    width: 80vw;
    text-align: center;
    position: absolute;
    left: 10vw;
    font-size: 10vw;
    background-image: -webkit-linear-gradient(top, #fffdfa, #ffeccc, #feeac5);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    top: 14vw;
    font-family: 'ZiZhiQuXiMaiTi';
  }
  .gashpon-banner {
    width: 65vw;
    height: 7.5vw;
    background-image: url('../../assets/icon/gashapon/gashpon-banner.png');
    background-size: 100% auto;
    margin-left: 17.5vw;
    position: absolute;
    top: 56vw;
  }
  .banner-item-style {
    width: 90%;
    height: 100%;
    font-size: 11px;
    font-weight: 400;
    color: #fffdf5;
    line-height: 12px;
    background: transparent;
  }
  .gashpon-lottery-size {
    width: 36.8vw;
    height: 12vw;
    margin-left: 31.6vw;
    background-image: url('../../assets/icon/gashapon/lottery-size-bg.png');
    background-size: 100% auto;
    position: absolute;
    top: 122vw;
  }
  .gashpon-lottery-size-tip {
    font-weight: 500;
    color: #fffdf5;
    font-size: 13px;
    margin-bottom: 4px;
  }
  .lottery-button-content {
    width: 87.2vw;
    height: 18vw;
    margin-left: 6.4vw;
    position: absolute;
    top: 133.33vw;
  }
  .lottery-record-content {
    width: 38vw;
    height: 7vw;
    position: absolute;
    background-image: url('../../assets/icon/gashapon/lottery-record-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    top: 154vw;
    left: 31vw;
    font-weight: 400;
    color: #fffdf5;
    font-size: 13px;
  }
  .lottery-button-one {
    width: 26.5vw;
    height: 16.7vw;
  }
  .lottery-button-item {
    width: 26.7vw;
    height: 18vw;
  }
  .lottery-button-one:active,
  .lottery-button-item:active {
    opacity: 0.6;
  }
  .lottery-shade-bg {
    z-index: 1997;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: #000000;
    opacity: 0.5;
  }
  .lottery-content {
    z-index: 1998;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  .lottery-bg1 {
    position: fixed;
    top: 43.8vw;
    width: 100vw;
    z-index: 1999;
  }
  .lottery-ball {
    z-index: 2000;
    position: fixed;
    width: 44vw;
    height: 44vw;
    top: 70vw;
    left: 28vw;
  }
  .lottery-ball-up {
    z-index: 2001;
    position: fixed;
    width: 44vw;
    height: auto;
    top: 70vw;
    left: 28vw;
  }
  .lottery-ball-down {
    z-index: 2000;
    position: fixed;
    width: 44vw;
    height: auto;
    top: 70vw;
    left: 28vw;
  }
  .lottery-open-ball {
    z-index: 2000;
    position: fixed;
    width: 90.67vw;
    height: auto;
    top: 32.53vw;
    left: 4.67vw;
  }
  .lottery-open-title {
    z-index: 2001;
    position: fixed;
    top: 54.6vw;
    font-size: 28px;
    width: 80vw;
    text-align: center;
    color: #fd6428;
    font-family: 'ZiZhiQuXiMaiTi';
    left: 11vw;
  }
  .lottery-open-content {
    z-index: 2001;
    position: fixed;
    width: 24vw;
    height: 24vw;
    top: 67.6vw;
    left: 38vw;
  }
  .lottery-open-content-title {
    z-index: 2001;
    position: fixed;
    width: 80vw;
    height: 24vw;
    top: 93vw;
    left: 10vw;
    text-align: center;
    font-weight: 700;
    color: #fffdf5;
    font-size: 15px;
  }
  .lottery-open-close-button {
    z-index: 2001;
    position: fixed;
    width: 31.6vw;
    height: auto;
    top: 78%;
    left: 13.6vw;
  }
  .lottery-open-close-button:active {
    opacity: 0.8;
  }
  .lottery-open-repreat-button:active {
    opacity: 0.8;
  }
  .lottery-open-repreat-button {
    z-index: 2001;
    position: fixed;
    width: 31.6vw;
    height: auto;
    top: 78%;
    right: 13.6vw;
  }
  .lottery-size-rule-content {
    width: 100vw;
    height: auto;
    background: #fae5c9;
    padding-bottom: 20px;
    padding-top: 30px;
    margin-top: -2px;
  }
  .get-lottery-size {
    width: 92vw;
    margin-left: 4vw;
    padding-top: 20px;
    margin-top: -2px;
    height: auto;
    border-radius: 1rem;
    border: 5px solid #fdc368;
    position: relative;
  }
  .get-lottery-size-title {
    width: 54vw;
    height: auto;
    position: absolute;
    top: -2.7vw;
    left: 19vw;
  }
  .get-lottery-size-item {
    width: 92%;
    height: 75px;
    border-radius: 10px;
    background: #ffecc1;
    margin: 20px 4% 20px 4%;
  }
  .get-lottery-size-item-title {
    font-weight: bold;
    color: #722e0a;
    font-size: 15px;
    margin-left: 4%;
  }
  .get-lottery-size-item-tip {
    font-weight: 500;
    color: #722e0a;
    font-size: 12px;
    margin-left: 4%;
    margin-top: 5px;
    width: 100%;
  }
  .get-lottery-size-button {
    width: 24.5vw;
    height: 8.4vw;
    background-image: url('../../assets/icon/gashapon/get-lottery-size-button.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-weight: bold;
    color: #fffdf5;
    font-size: 13px;
    margin-right: 4%;
    margin-left: 10px;
  }
  .get-lottery-size-button:active {
    opacity: 0.6;
  }
  .lottery-rule {
    background: #fcedc6;
    border-radius: 10px;
    height: auto;
    white-space: pre-wrap;
    word-break: break-all;
    width: 80%;
    padding: 10px 5%;
    margin: 40px 5% 20px 5%;
    font-size: 0.875rem !important;
    font-family: NotoSansHans-Regular, NotoSansHans !important;
    font-weight: 500 !important;
    color: #722e0a !important;
    line-height: 1.5rem !important;
    p {
      font-size: 0.875rem !important;
      font-family: NotoSansHans-Regular, NotoSansHans !important;
      font-weight: 500 !important;
      color: #722e0a !important;
      line-height: 1.5rem !important;
      span {
        font-size: 0.875rem !important;
        font-family: NotoSansHans-Regular, NotoSansHans !important;
        font-weight: 500 !important;
        color: #722e0a !important;
        line-height: 1.5rem !important;
      }
    }
    img {
      width: 100%;
      height: auto;
    }
  }
  .gashapon-agreement {
    .gashapon-logo {
      width: 100px;
      height: auto;
      margin-bottom: 5px;
    }
    .gashapon-logo-title {
      font-size: 12px;
      font-weight: bold;
      color: #080808;
      margin-bottom: 17px;
    }
    .gashapon-agreement-title,
    .gashapon-agreement-tip {
      font-size: 12px;
      font-weight: 400;
      color: #3d3d3d;
      margin-bottom: 5px;
    }
  }
  .lottery-open-content-more {
    width: 92vw;
    height: 76vw;
    position: fixed;
    background-image: url('../../assets/icon/gashapon/lottery-open-ball-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
    left: 4vw;
    top: 40vw;
    z-index: 2000;
  }
  .lottery-open-list-content {
    width: 80vw;
    height: 40vw;
    position: fixed;
    top: 48vw;
    left: 10vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .lottery-open-list-content-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 15vw;
    height: 19vw;
  }
  .lottery-open-list-content-item1 {
    width: 15vw;
    height: 15vw;
    background-image: url('../../assets/icon/gashapon/lottery-open-list-content-item.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .lottery-open-list-content-item2 {
    width: 15vw;
    height: 15vw;
    background-image: url('../../assets/icon/gashapon/lottery-open-list-none-item.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .lottery-open-more-close-button {
    z-index: 2001;
    position: fixed;
    width: 31.6vw;
    height: auto;
    top: 98vw;
    left: 13.6vw;
  }
  .lottery-open-more-repreat-button {
    z-index: 2001;
    position: fixed;
    width: 31.6vw;
    height: auto;
    top: 98vw;
    right: 13.6vw;
  }
  .lottery-open-more-close-button:active,
  .lottery-open-more-repreat-button:active {
    opacity: 0.6;
  }
  .lottery-open-list-item-integral {
    width: 8vw;
    height: 8vw;
    background-image: url('../../assets/icon/gashapon/lotytery-integral-bg.png');
    background-size: 100% auto;
    background-repeat: no-repeat;
  }
  .lottery-open-list-item-integral-tip {
    font-size: 14px;
    color: #e5a722;
    font-weight: bold;
    display: inline-block;
    transform: scale(0.5);
  }
  .lottery-open-list-item-thank {
    width: 7vw;
    height: 7vw;
  }
  .lottery-open-list-item-bottom {
    width: 13vw;
    height: 4vw;
    background: #e66f47;
    border-radius: 1rem;
    margin-top: 4px;
    p {
      font-size: 14px;
      display: inline-block;
      transform: scale(0.5);
      color: #ffffff;
      white-space: nowrap;
    }
  }
  .lottery-open-list-item-lottery-img {
    width: 13vw;
    height: 13vw;
    border-radius: 10px;
  }
  .lottery-open-list-item-lottery-size {
    width: 8vw;
    height: auto;
  }
}
/deep/.van-notice-bar {
  color: #ffffff;
  padding: 0;
  background-color: transparent;
}
.notice-swipe {
  height: 6.8vw;
  line-height: 6.8vw;
}
/deep/.van-notice-bar__wrap {
  flex: initial;
  width: 94%;
  margin-left: 3%;
}
</style>
